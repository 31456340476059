@import "src/styles/colors.scss";

.header {
    display: flex;
    column-gap: 1rem;
    row-gap: 0.5rem;
    align-items: center;
    h1 {
        flex: 1 1 auto;
        color: $primary-10;
        font-size: 1.5rem;
        font-weight: 400;
        margin: 0;
        padding: 0;
        &:first-child {
            color: $primary-30;
        }
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
    .toggle {
        flex: 0 0 100px;
    }
    .disabledLabel {
        border: $primary-50 1px solid;
        color: $primary-20 !important;
        border-radius: 8px;
        margin-left: 0.75rem;
        padding: 0.25rem 0.5rem;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
    }
}

.headerActionButton {
    margin-left: -0.5rem;
}
