.auditsMenuDropdown {
  & > div {
    min-width: 280px;
  }
  &.disabledMenu {
    > button {
      opacity: 0;
    }
  }
}

.waitingIcon {
  width: 36px;
  height: 36px;
  padding: 5px;
  display: inline-block;
  animation: rotationAnimation linear 1s;
  animation-iteration-count: infinite;
}

@keyframes rotationAnimation {
  0% {
    transform: rotate(0deg) scaleX(-1);
  }
  100% {
    transform: rotate(360deg) scaleX(-1);
  }
}
