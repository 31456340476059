@import "src/styles/colors.scss";

.userBadge {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  cursor: pointer;
  height: 100%;
  &:hover .profileImage,
  &:focus .profileImage,
  &:active .profileImage {
    background-color: $primary-50;
    color: white;
  }
}

.information {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: end;
  margin-right: 10px;
  color: $primary-10;

  .name {
    font-weight: 400;
    padding-bottom: 8px;
  }
  .role {
    font-size: 16px;
    font-weight: 600;
  }
}

.profileImage {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: $primary-20;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: background-color 0.2s ease, color 0.2s ease;
  border: 2px solid $primary-50;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}