@import "src/styles/colors.scss";

.input {
    margin-bottom: 0 !important;
}

.highlightedSyntax {
    position: relative;
    pre {
        margin: 0;
        font-weight: 400;
        border-radius: 0.25rem;
        padding: 0.75rem 2rem 0.75rem 0.75rem !important;
        white-space: pre-wrap !important;
    }
    code {
        white-space: pre-wrap !important;
    }
    .copyToClipboard {
        position: absolute;
        top: 0.25rem;
        right: 0.25rem;
    }
    .sqlQueryButton {
        position: absolute;
        top: 0.25rem;
        right: 1.75rem;
    }
}

.sqlQueryResult {
    margin-top: 0.25rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    span {
        font-size: 16px;
        font-weight: 600;
        color: $primary-30;
        margin-right: 0.5rem;
    }
}

.syncIcon {
    width: 22px;
    height: 22px;
    -webkit-animation-name: spin;
    -webkit-animation-duration: 4000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 4000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    animation-duration: 4000ms;
    animation-name: spin;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}
@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
