@import "src/styles/colors.scss";
@import "src/styles/dimensions.scss";
@import "src/styles/mixins";

.auditContainer {
  @include mobile-container;
  @include sm {
    max-width: 700px;
    min-width: $padded-container-min-width-desktop;
    padding-top: $padded-container-padding-top-desktop;
    padding-bottom: $padded-container-padding-bottom-desktop;
  }
}

.tightLabel {
  margin-bottom: 0 !important;
}

.card {
  padding: 0 0 1rem 0;
}
section {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
.tabContent {
  flex: 1 0 auto;
  padding: 2rem;
  &.elementsContainer {
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
  }
}
