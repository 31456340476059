/* --- PRIMARY PALETTE --- */
$primary-120: #05080a;
$primary-110: #121a1f;
$primary-100: #23323a;
$primary-90: #344751;
$primary-80: #425a66;
$primary-70: #516e7d;
$primary-60: #5d7d8e;
$primary-50: #75909f;
$primary-40: #8da4b1;
$primary-30: #adbec8;
$primary-20: #ccd8df;
$primary-15: #dae4eb;
$primary-10: #e9eff4;
$primary-5: #f5f8fa;
$primary-0: #fcfcfc;

$prim-opacity-110-70: rgba(18, 26, 31, 0.7);
$prim-opacity-20-70: rgba(204, 216, 223, 0.7);
$prim-opacity-80-50: rgba(66, 90, 102, 0.5);

/* --- SECONDARY PALETTE --- */
$dark_accent-1: #4da19a;
$dark_accent-2: #80bcb7;
$dark_accent-3: #01796f;
$dark_accent-darker-50: rgba(77, 161, 154, 0.5);
$dark_accent-darker-30: rgba(77, 161, 154, 0.3);
$dark_accent-darker-15: rgba(77, 161, 154, 0.15);

$light_accent: #34948c;
$light_accent-lighter-50: rgba(52, 148, 140, 0.5);
$light_accent-lighter-30: rgba(52, 148, 140, 0.3);
$light_accent-lighter-15: rgba(52, 148, 140, 0.15);

/* --- NOTIFICATION PALETTE --- */
$dark_notification-red: #ed305e;
$dark_notification-green: #75eb9d;
$dark_notification-blue: #304ffe;
$dark_notification-dark-green: #33776b;
$dark_notification-purple: #8b46ea;
$dark_notification-orange: #e65300;

$dark_notification-success: #1ac85f;

$light_notification-green: #37ac66;
$light_notification-white: #ffffff;

$dark_overlay: 0px 8px 16px #05080a;

$event: #ffa824;
