$padded-container-width-mobile: 100%;
$padded-container-padding-top-mobile: 0.4rem;
$padded-container-padding-bottom-mobile: 1rem;
$padded-container-min-width-mobile: 100px;
$flex-gap-default: 1rem;

$padded-container-max-width-desktop: 1000px;
$padded-container-padding-top-desktop: 1rem;
$padded-container-padding-bottom-desktop: 1rem;
$padded-container-min-width-desktop: 300px;
