@import "src/styles/colors.scss";
@import "src/styles/dimensions.scss";
@import "src/styles/mixins";

.newAuditContainer {
    @include mobile-container;
    @include md {
        @include desktop-container;
    }
    .newAuditBody {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: calc(100vh - 255px);
    }
    h2 {
        margin: 0 0 1.5rem 0;
    }
}

.stepContent {
    padding: 2rem 1rem 1rem;
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
}

.input {
    margin-bottom: 0 !important;
}

.review {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.reviewSection {
    padding: 1rem;
    background-color: $primary-80;
    border-radius: 0.5rem;
    position: relative;

    .editStepButton {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}

.infoContainer {
    gap: 1.5rem;
    display: flex;
    flex-wrap: wrap;
}
.configContainer {
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
}
.statement {
    padding: 0;
}
