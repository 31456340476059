@import "src/styles/colors.scss";

.rdt {
  position: relative;
  width: 101px;
  & input {
    border: 1px solid $primary-50;
    border-radius: 4px;
    background-color: $primary-80;
    color: $primary-0;
    width: 100%;
    padding: 9px 12px;
    line-height: 22px;
    font-size: 1rem;
    &::placeholder {
      color: $primary-0;
    }
    &:hover {
      background-color: $primary-70;
    }
    &:focus {
      background-color: $primary-70;
      border: 1px solid $dark_accent-2;
      outline: none;
    }
  }
  & > div:nth-of-type(2) {
    background-color: $primary-70 !important;
    border: 1px solid $dark_accent-2 !important;
    border-radius: 4px;
    outline: none !important;
    min-width: 100px !important;
    width: 100% !important;
  }
}

.container {
  position: relative;
  .icon {
    color: $primary-30;
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    width: 22px;
    height: 22px;
  }
  &.error {
    input {
      border-color: $dark_notification-red;
    }
  }
}

.errorMessage {
  color: $dark_notification-red;
}
