@import "src/styles/colors.scss";

.outerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $primary-90;
  padding: 0 1rem 1rem 1rem;
  border-radius: 4px;
  box-shadow: 0px 1px 1px $primary-120;
  box-sizing: border-box;
  min-height: calc(100vh - 96px);
  p {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  max-width: 700px;
}

.cardHeader {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: $primary-20;
  font-size: 1.5rem;
  .title {
    flex: 1 1 auto;
  }
  gap: 1rem;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}
