@import "src/styles/dimensions.scss";
@import "src/styles/mixins.scss";

.incidentsContainer {
  @include mobile-container;
  @include md {
    @include desktop-container;
  }
}
.incidentsTableContainer {
  width: 100%;
}
.incidentsTable {
  tbody tr:hover td {
    cursor: initial;
  }
}

.incidentsDesktopFilters {
  & > div {
    display: none;
    @include md {
      display: block;
    }
  }
  @include md {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.liveIndicator {
  margin-left: 1rem;
  margin-bottom: 1rem;
  @include md {
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
}


