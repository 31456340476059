@import "src/styles/colors.scss";
@import "src/styles/mixins.scss";

.sidebarIcon {
  width: 50px;
  @include md {
    display: none;
  }
  & > input[type=checkbox]:checked #mainMenu {
    transform: translateX(0);
  }
  & > input[type=checkbox] {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
  }
  & > .sidebarIconToggle {
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    z-index: 99;
    top: 22px;
    left: 15px;
    height: 22px;
    width: 22px;
  }
  & .spinner {
    transition: all 0.3s;
    box-sizing: border-box;
    position: absolute;
    height: 3px;
    width: 100%;
    color: $primary-10;
    background-color: $primary-10;
  }
  & .horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
  }
  & .diagonal.part1 {
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    float: left;
  }
  & .diagonal.part2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
  }
  & > input[type=checkbox]:checked ~ .sidebarIconToggle > .horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    opacity: 0;
  }
  & > input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part1 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(135deg);
    margin-top: 8px;
  }
  & > input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part2 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(-135deg);
    margin-top: -9px;
  }
}