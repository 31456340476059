.container {
  display: flex;
  gap: 1rem;
}

.containercontainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  .step {
    display: flex;
    gap: 1rem;
    flex: 0 1 auto;
    .stepSelector {
      width: 75px;
      margin-bottom: 0 !important;
    }
  }
  .startStop {
    flex: 1 0 300px;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
}

.text {
  line-height: 42px;
}
