@import "src/styles/colors.scss";

.radioButtons{
  & h3 {
    color: $primary-30;
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  & fieldset {
    border: none;
    margin: 0;
    padding: 0;
    font-size: 1rem;
  }
}

.radioButton {
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  & input:checked ~ .checkMark {
    background-color: $dark_accent-3;
  }
  & label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    color: $primary-15;
  }
  &:hover .checkMark {
    background-color: $primary-15;
  }
}

.checkMark {
  height: 0.7rem;
  width: 0.7rem;
  margin-top: 0.2rem;
  margin-right: 0.7rem;
  background-color: $primary-30;
  border-radius: 50%;
}

.labels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //column-gap: 1rem;
  width: 100%;
  & span {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 50%;
  }
}