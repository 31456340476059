@import "src/styles/colors.scss";
@import "src/styles/mixins.scss";

.contentNotFound {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 94vh;
  background-color: $primary-90;
  h1 {
    margin: 1rem;
  }
  img {
    width: 300px;
    height: 300px;
  }
  @include md {
    img {
      width: 400px;
      height: 400px;
    }
  }
}