.buttonsBar {
    flex: 1 0 auto;
}

.buttonGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding: 0 1rem;
}
