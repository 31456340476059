@import "src/styles/deviceWidth.scss";
@import "src/styles/dimensions.scss";

@mixin xs {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin mobile-container {
  margin-left: auto;
  margin-right: auto;
  width: $padded-container-width-mobile;
  min-width: $padded-container-min-width-mobile;
  padding-top: $padded-container-padding-top-mobile;
  padding-bottom: $padded-container-padding-bottom-mobile;
}

@mixin desktop-container {
  margin-left: auto;
  margin-right: auto;
  max-width: $padded-container-max-width-desktop;
  min-width: $padded-container-min-width-desktop;
  padding-top: $padded-container-padding-top-desktop;
  padding-bottom: $padded-container-padding-bottom-desktop;
}
