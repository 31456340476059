@import "src/styles/colors.scss";

.userMenu {
  position: relative;
  margin-right: 15px;
  .menuWrapper {
    position: absolute;
    z-index: 2000;
  }
  .menu {
    padding: 0;
    position: relative;
    background-color: $primary-90;
    box-shadow: 0px 2px 4px $primary-120;
    border-radius: 8px;
    box-sizing: border-box;
    top: -1px;
    overflow-y: auto;
    min-width: 256px;
    max-height: 500px;
    ul {
      margin: 0;
      padding: 0;
      font-size: 1rem;
      cursor: pointer;
      text-decoration: none;
      list-style:none;
      transition: outline 0.2s ease;
      word-break: break-word;
    }
    li {
      padding-left: 1rem;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      display: block;
      overflow: hidden;
      position: relative;
      &:focus {
        background: $light_accent-lighter-30;
        outline: none;
      }
      &:hover {
        background: $light_accent-lighter-30;
        outline: none;
      }
      & button {
        position: absolute;
        padding-left: 1rem;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: none;
        background-color: inherit;
        font-size: 1rem;
        text-align: left;
        color: $primary-10;
        cursor: pointer;
      }
    }
  }
}