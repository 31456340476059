@import "src/styles/colors.scss";

.container {
    min-width: 400px;
    margin: 1rem auto auto auto;
    background-color: $primary-80;
    color: $primary-5;
    padding: 1.5rem 2rem 1rem;
    border-radius: 0.5rem;
    h3,
    p {
        margin: 0;
    }
    .errorDescription {
        margin-top: 1rem;
        border-left: 1px solid $primary-0;
        font-style: italic;
        padding-left: 0.5rem;
    }
    .buttonGroup {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 2rem;
        margin-top: 2rem;
    }
}
