@import "src/styles/colors.scss";
@import "src/styles/shadows";
@import "src/styles/mixins";

.contextMenu {
  position: relative;
  line-height: 22px;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
    padding-left: 1rem;
    padding-right: 1rem;
    min-height: 3rem;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $dark_accent-darker-30;
    }
  }
}

.contextMenuDropdown {
  z-index: 2;
  padding: 0;
  position: absolute;
  background-color: $primary-80;
  box-shadow: $dark_floating;
  border-radius: 8px;
  box-sizing: border-box;
  top: 50px;
  right: 0px;
  overflow-y: auto;
  min-width: 210px;
  max-height: 500px;

  li > * {
    background-color: transparent;
    border: none;
    width: 100%;
    color: $primary-20;
    font-size: 1.25rem;
    min-height: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 1rem;
    }
  }

  .disabledItem {
    color: $primary-50;
    pointer-events: none;
    cursor: default;
  }
}

.menuButton {
  z-index: 1;
}
