.input {
    margin-bottom: 0 !important;
}

.markDown {
    overflow-wrap: break-word;
    h1,
    h2,
    ul,
    ol {
        margin: 0;
        padding-left: 1rem;
    }
    h1,
    h2 {
        font-size-adjust: 0.4;
        font-weight: 600;
    }
    & :first-child {
        margin-top: 0;
    }

    pre {
        margin: 0;
        font-weight: 400;
        border-radius: 0.25rem;
        white-space: pre-wrap;
        background: rgb(46, 52, 64);
        padding: 0.75rem 2rem 0.75rem 0.75rem;
    }
}
