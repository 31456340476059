@import "src/styles/colors.scss";
@import "src/styles/mixins.scss";

.tabbedFilter {
  display: flex;
  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    flex-grow: 1;
    height: 70%;
    color: $primary-30;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 20px;
    padding-bottom: 5px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    cursor: pointer;
    border: none;
    background-color: transparent;
    @include md {
      flex-grow: 0;
      font-size: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    &.active {
      border-bottom: 1px solid $dark_accent-2;
    }
    &.disabled {
      cursor: pointer;
      color: $primary-50;
    }
    & > span {
      margin-left: 5px;
    }
  }
  & > button:not(:first-of-type) {
    margin-left: 5px;
    @include md {
      margin-left: 0.5rem;
    }
  }
}
