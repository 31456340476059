@import "src/styles/colors.scss";
@import "src/styles/dimensions.scss";
@import "src/styles/mixins";

.servicesContainer {
  @include mobile-container;
  @include md {
    @include desktop-container;
  }
  h1 {
    margin-bottom: 0;
    @include sm {
      margin-bottom: $flex-gap-default;
    }
  }
}

.header {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  h1 {
    margin-bottom: 0;
  }
  > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    @include md {
      width: auto;
    }
  }
}

.liveIndicator {
  margin-left: 1rem;
  margin-bottom: 1rem;
  @include md {
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
}

.searchField {
  margin-left: 1rem;
  & > div {
    margin-bottom: 0 !important;
  }
  margin-bottom: 0 !important;
  min-width: 300px;
  width: calc(100% - 2rem) !important;
  @include md {
    max-width: 300px;
    margin-left: 0;
  }
}