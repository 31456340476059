@import "src/styles/colors.scss";

.schedule {
    margin: 0;
}
.inputsContainer {
    background-color: $prim-opacity-80-50;
    border-radius: 0.5rem;
    padding: 1rem;
}

.schedulingMode {
    margin-bottom: 1rem !important;
}

.validationMessage {
    color: $dark_notification-red;
}
