@import "../../../../../../styles/colors";

.postponeCard {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
