@import "src/styles/colors.scss";

.statusLabel {
  padding: 0.125rem 0.5rem;
  border-radius: 4px;
  width: fit-content;
  color: $primary-0;
  &.green {
    background-color: $dark_notification-dark-green;
  }
  &.yellow {
    background-color: $dark_notification-orange;
  }
  &.red {
    background-color: $dark_notification-red;
  }
  &.gray {
    background-color: $primary-60;
  }
}
