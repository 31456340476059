@import "src/styles/colors.scss";
@import "src/styles/deviceWidth.scss";

.sqlResultsContainer {
  margin: 1rem;
}

.sqlResultsHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;

  h1 {
    margin: 0;
  }
}

.noData {
  min-height: 200px;
  background-color: $primary-90;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  margin-top: 0.5rem;
}

@media only screen and (max-width: $screen-md-min) {
  .sqlResultTable {
    td:before {
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 8rem;
    }
  }
}

.sqlQueryResult {
  margin-top: 0.25rem;
  font-weight: 400;
  display: flex;
  align-items: center;

  span {
    font-size: 16px;
    font-weight: 600;
    color: $primary-30;
    margin-right: 0.5rem;
  }
  span:nth-of-type(2) {
    margin-left: 0.5rem;
  }
}

.syncIcon {
  width: 22px;
  height: 22px;
  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  animation-duration: 4000ms;
  animation-name: spin;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
