@import "src/styles/mixins.scss";

.skeletonLoaderContainer {
  @include mobile-container;
  @include md {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}