@import "../../../styles/colors";

.step_indicator {
    display: flex;
    align-items: center;
    min-height: 30px;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;

    .step {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        border: none;
        background: none;
        outline: none;
        width: 16px;
        height: 100%;
        justify-content: center;

        &:focus {
            .step_circle {
                outline: none;
                box-shadow: 0 0 5px $light_notification-white;
            }
        }

        &_label {
            text-align: center;
            position: absolute;
            bottom: -1.5em;
            color: $primary-70;
            font-family: "Source Sans Pro", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            white-space: nowrap;
        }

        &_circle {
            height: 24px;
            width: 24px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $primary-80;
            position: relative;
            border: 4px solid $primary-70;
        }

        &_innerCircle {
            display: none;
            height: 8px;
            width: 8px;
            border-radius: 50%;
            background: white;
        }

        &:not([disabled]) .step_circle {
            cursor: pointer;
        }
    }

    .indicator_line {
        width: 100%;
        background: $primary-70;
        min-height: 4px;
        &_active {
            min-height: 4px;
            background: $dark_accent-1;
        }
    }

    .step.active {
        .step_circle {
            border: 4px solid $dark_accent-1;
        }

        .step_label {
            color: $dark_accent-1;
        }
    }

    .step.selected {
        .step_innerCircle {
            display: inline-block;
        }

        .step_label {
            color: $light_notification-white;
        }
    }
}
