@import "src/styles/colors.scss";

.overlay {
    background-color: $prim-opacity-110-70;
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0.5rem;
    padding: 0;
    width: 500px;
    max-width: 100%;
    max-height: 100%;
    box-shadow: $dark_overlay;
    background: $primary-90;
    outline: none;
    overflow: auto;
}

.header {
    padding: 1rem 1rem;
    h2 {
        margin: 0;
        font-weight: 600;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content {
    padding: 1rem 1rem 2rem 1rem;
}

.footer {
    padding: 1rem 1rem;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}
