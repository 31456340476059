@import "src/styles/colors.scss";
@import "src/styles/shadows";
@import "src/styles/mixins";

.filterMenu {
  position: relative;
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style-type: none;
    padding-left: 1rem;
    padding-right: 1rem;
    min-height: 3rem;
    display: flex;
    align-items: center;
    &:hover {
      background-color: $dark_accent-darker-30;
    }
    &.underlined {
      border-bottom: 1px solid $primary-60;
    }
  }
}

.hideDesktop {
  display: none;
  @include md {
    display: flex;
  }
}

.filterMenuWrapper {
  z-index: 2000;
  padding: 0;
  position: absolute;
  background-color: $primary-80;
  box-shadow: $dark_floating;
  border-radius: 8px;
  box-sizing: border-box;
  top: 50px;
  right: 0px;
  overflow-y: auto;
  min-width: 210px;
  max-height: 500px;
  @include md {
    top: 60px;
  }
  button {
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 0 1rem 0 1rem;
    color: $primary-20;
    font-size: 1.25rem;
    min-height: 3rem;
    &.back {
      span {
        justify-content: flex-start;
        gap: 1rem;
      }
      padding-left: 0;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.filterChipsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 0.5rem 0.5rem 0 1rem;
  @include md {
    margin: 0.5rem 0 0 0;
  }
}

.filterChip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primary-70;
  border-radius: 8px;
  padding: 4px 8px 4px 12px;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  color: $primary-20;
  cursor: pointer;
  &:hover {
    background-color: $primary-50;
  }
  svg {
    margin-left: 4px;
    margin-top: 3px;
    height: 1rem;
    width: 1rem;
  }
}
