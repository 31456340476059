.errorDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.highlightedSyntax {
  position: relative;
  pre {
    margin: 0;
    font-weight: 400;
    border-radius: 0.25rem;
    padding: 0.75rem 2rem 0.75rem 0.75rem !important;
    overflow-y: auto !important;
    max-height: 500px;
  }
  .copyToClipboard {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
  }
}

.modal {
  width: 700px;
}
