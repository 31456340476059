.runOnce {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.inputs {
  display: flex;
  gap: 1rem;
}

.text {
  line-height: 42px;
}
