@import "src/styles/colors.scss";

.label {
  color: $primary-30;
}

.content {
  color: $primary-10;
  font-size: 18px;
  font-weight: 600;
}

.vertical {
  .label {
    margin-bottom: 0.5rem;
    font-size: 16px;
    font-weight: 600;
  }
}

.horizontal {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .label {
    flex: 0 0 auto;
  }
}
