@import "./colors.scss";
@import "./mixins.scss";

body {
    margin: 0;
    font-size: 18px;
}

*:not(code) {
    font-family: Source Sans Pro, sans-serif;
}
code {
    font-family: monospace;
    span {
        font-family: monospace;
    }
}

a {
    color: $dark_accent-1;
}
h1 {
    margin-top: 0;
    padding-left: 1rem;
    @include md {
        padding-left: 0;
    }
}
