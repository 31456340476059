@import "src/styles/colors.scss";
@import "src/styles/mixins";

.tabbedNavigation {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background-color: $primary-80;
  min-height: 48px;
  & button {
    flex: 1 1 auto;
    border: none;
    background-color: transparent;
    font-size: 18px;
    color: $primary-30;
    font-weight: 600;
  }
  & button:hover {
    color: $primary-5;
    background-color: darken($primary-70, 5%);
    cursor: pointer;
  }
  & .active {
    color: $primary-10;
    border-bottom: 3px solid $dark_accent-2;
  }
}
