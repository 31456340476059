@import "src/styles/colors.scss";
@import "src/styles/deviceWidth.scss";
@import "src/styles/mixins.scss";

.incidentsTable {
  margin-top: 1rem;
  @include md {
    margin-top: 0;
  }
  tbody tr:hover td {
    cursor: default !important;
  }
  tbody tr:hover .unassignedButton {
    display: block;
  }
  tbody tr .unassignedButton {
    display: none;
  }
  tbody tr:hover .unassignedText {
    display: none;
  }
  tbody tr .unassignedText {
    display: block;
  }

  table {
    td:nth-child(2) {
      border-radius: 4px 4px 0 0 !important;
    }
    td:nth-last-child(2) {
      border-radius: 0 0 4px 4px !important;
      padding-bottom: 1rem !important;
      @include md {
        border-radius: 0 !important;
        padding: 0.5rem !important;
      }
    }
    @include md {
      td:nth-child(2) {
        padding-left: 1rem;
        border-radius: 4px 0 0 4px !important;
      }
    }
  }
  tbody {
    tr {
      position: relative;
      margin-top: 1rem;
      @include md {
        position: initial;
      }
    }
  }
}

.contextMenuCell {
  display: none !important;
  @include md {
    display: table-cell !important;
    text-align: right;
  }
}
.contextMenuCellMobile {
  position: absolute !important;
  top: 0.25rem;
  right: 0.25rem;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  box-shadow: none !important;
  @include md {
    display: none;
  }
}
.mobileSortFilter {
  @include md {
    display: none;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  > div:nth-child(2) {
    margin-right: 1rem;
  }
}

.noData {
  min-height: 200px;
  background-color: $primary-90;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  margin-top: 0.5rem;
}

.critical {
  font-weight: 600 !important;
  color: white !important;
}

.clickableText {
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}
