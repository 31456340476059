@import "src/styles/colors.scss";
@import "src/styles/mixins";
@import "src/styles/dimensions.scss";

.container {
  max-width: 600px;
  padding: 2rem;
}

.auditRunStatus {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
}
.runNowButton {
  align-self: flex-end;
}

.highlight {
  animation: magnify 1.5s;
}

@keyframes magnify {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  50% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.errorMessage {
  flex-basis: 100%;
  color: $dark_notification-red;
  margin-top: 1rem;
}
