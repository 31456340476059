@import "./colors.scss";
:root {
    --toastify-color-light: #{$primary-5};
    --toastify-color-info: #3498db;
    --toastify-color-success: #{$dark_notification-success};
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #{$dark_notification-red};
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-text-color-light: #{$primary-90};
}
