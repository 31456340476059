@import "src/styles/colors.scss";

.container {
    background: $dark_notification-dark-green;
    color: $primary-10;
    padding: 0.125rem 0.5rem;
    border-radius: 0.25rem;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    display: inline-flex;
    align-items: center;

    .circle {
        width: 0.5rem;
        height: 0.5rem;
        background: white;
        border-radius: 50%;
        display: inline-block;
        margin-right: 0.25rem;
    }

    .blink {
        animation: blinker 1.5s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
    }

    &.offline {
        background: $dark_notification-red;
        .circle {
            width: 0.75rem;
            height: 0.75rem;
            position: relative;
            background: $dark_notification-red;
        }
        .circle:after {
            position: absolute;
            top: -3px;
            left: 0;
            content: "\2715";
            color: $primary-20;
        }
        .blink {
            animation: none;
        }
    }
}
@keyframes blinker {
    from {
        opacity: 0.1;
    }
    to {
        opacity: 0.9;
    }
}
