@import "src/styles/mixins.scss";
@import "src/styles/colors.scss";

.expectedResult {
  margin-bottom: 0;

  @include md {
    justify-content: flex-start;
  }
  & div {
    margin-bottom: 0 !important;
  }
}
.operator {
  flex: 1 1 80px;
}
.valueType {
  flex: 1 1 120px;
}
.value {
  flex: 1 1 150px;
}
.resultText {
  line-height: 42px;
}
.inputsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
  background-color: $prim-opacity-80-50;
  border-radius: 0.5rem;
  padding: 1rem;
}
.errorMessage {
  color: $dark_notification-red;
}
