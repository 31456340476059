@import "src/styles/colors.scss";
@import "src/styles/mixins.scss";

.container {
	margin-bottom: 0.5rem;
	fieldset {
		border: none;
		margin-left: 0;
		padding: 0;
		&:disabled {
			pointer-events: none;
		}
		legend {
			color: $primary-30;
			padding: 0;
			font-weight: 600;
			font-size: 16px;
		}
		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
		}
	}
}

.optionsContainer {
	display: flex;
	margin-top: 1rem;
	margin-bottom: 0.25rem;
	flex-wrap: wrap;
	gap: 0.5rem;
	justify-content: flex-start;
	@include md {
		justify-content: space-between;
		gap: 0;
	}
}

.optionLabel {
	display: block;
	border: 1px solid $primary-40;
	border-radius: 4px;
	height: 40px;
	min-width: 55px;
	position: relative;
	text-align: center;
	line-height: 36px;
	color: $primary-20;
	&.error {
		border-color: $dark_notification-red;
	}
}

.option:hover .optionLabel,
.option input:focus + .optionLabel {
	background-color: $primary-80;
	color: $primary-10;
}

.option:hover .optionLabel {
	cursor: pointer;
}

.option input:focus + .optionLabel {
	box-shadow: 0 0 4px $dark_accent-2;
}

.option input:checked + .optionLabel {
	background-color: $dark_accent-darker-50;
	border: 1px solid $dark_accent-1;
	color: white;
}

.errorMessage {
	color: $dark_notification-red;
}
