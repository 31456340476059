.bottomNavigationContainer {
    margin-top: 1rem;
}

.bottomNavigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    button {
        margin: 1rem 1rem 0rem 1rem;
    }
}
