@import "src/styles/colors.scss";
@import "src/styles/deviceWidth.scss";
@import "src/styles/mixins";

.sortableTable {
  table {
    border-spacing: 0 10px;
    width: 100%;
    th,
    td {
      padding: 0.5rem;
    }
    td {
      background-color: $primary-90;
      color: $primary-15;
      box-shadow: 0 1px 0 $primary-120;
      font-weight: 400;
      font-size: 1rem;
      a {
        color: $primary-15;
      }
    }
    td:first-child {
      padding-left: 1rem;
      border-radius: 4px 0 0 4px;
    }
    td:last-child {
      padding-right: 1rem;
      border-radius: 0 4px 4px 0;
    }
    th {
      box-sizing: border-box;
      font-size: 1rem;
      background-color: $primary-80;
      color: $primary-40;
      height: 36px;
      line-height: 20px;
      font-weight: 600;
      text-align: left;
      &:hover {
        cursor: pointer;
        color: $primary-30;
      }
      &.default {
        &::after {
          content: "";
          width: 3px;
          height: 3px;
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
          border-bottom: 6px solid transparent;
          display: inline-block;
          margin-left: 6px;
          margin-bottom: 2px;
        }
      }
      &.down {
        &::after {
          content: "";
          width: 3px;
          height: 3px;
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
          border-bottom: 6px solid $primary-40;
          display: inline-block;
          margin-left: 6px;
          margin-bottom: 2px;
          transition: 0.4s ease;
          transform: rotate(180deg);
        }
        &:hover::after {
          border-top-color: $primary-30;
        }
      }
      &.up {
        &::after {
          content: "";
          width: 3px;
          height: 3px;
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
          border-bottom: 6px solid $primary-40;
          display: inline-block;
          margin-left: 6px;
          margin-bottom: 2px;
          transition: 0.4s ease;
        }
        &:hover::after {
          border-bottom-color: $primary-30;
        }
      }
    }
    th:first-child {
      padding-left: 1rem;
      border-radius: 4px 0 0 0;
    }
    th:last-child {
      padding-right: 1rem;
      border-radius: 0 4px 0 0;
    }

    tbody tr:hover td {
      cursor: pointer;
      background-color: $primary-70;
    }
  }
  .highlighted {
    color: $primary-0;
  }
  .faded {
    color: $primary-30;
  }
  .fastTransition {
    transition: 0.2s ease;
  }
}
@media only screen and (max-width: $screen-md-min) {
  .sortableTable {
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }
    td:before {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0.5rem 0.5rem 0.5rem 2rem;
      width: 15%;
      white-space: nowrap;
      text-align: left;
      font-size: 1rem;
      color: $primary-40;
    }
    table {
      td {
        border: none;
        position: relative;
        padding-left: 35%;
        white-space: normal;
        text-align: left;
      }
      td:first-child {
        border-radius: 4px 4px 0 0;
        margin-top: 1rem;
        padding-left: 35%;
        padding-top: 1rem;
      }
      td:first-child:before {
        padding-top: 1rem;
      }
      td:last-child {
        border-radius: 0 0 4px 4px;
        padding-bottom: 1rem;
      }
    }
  }
  .sortableTable td:before {
    content: attr(data-title);
  }
}

.mobileTableHead {
  @include md {
    display: none;
  }
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
  .sortFieldSelect {
    width: 85%;
    min-width: 150px;
  }
  .sortOrderButton {
    width: 30px;
    min-width: 30px;
  }
}

nav + section > div.mobileTableHead {
  margin-top: 0;
}
