@import "src/styles/mixins.scss";
@import "src/styles/colors.scss";

.assignUserCell {
  min-height: 32px;
  min-width: auto;
  @include md {
    min-width: 110px;
    min-height: auto;
  }
  .text {
    color: $primary-30;
  }
}
