@import "src/styles/colors.scss";
@import "src/styles/mixins.scss";

$menu-height: 64px;

.mainHeader {
  position: fixed;
  z-index: 9999;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0;
  width: 100%;
  height: $menu-height;
  background: $primary-90;
  box-shadow: 0px 2px 4px $prim-opacity-110-70;
}
.active {
  background-color: $light_accent-lighter-15;
}
.mainMenu {
  height: 100%;
  position: fixed;
  left: 0;
  margin-top: $menu-height;
  transform: translateX(-100%);
  background: $primary-90;
  &.open {
    transition: transform 250ms ease-in-out;
    transform: translateX(0);
  }
  @include md {
    display: flex;
    position: static;
    margin-top: 0;
    left: unset;
    transform: none;
    transition: none;
  }
}
.mainMenuInner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  border-top: 1px solid $primary-50;
  @include md {
    flex-direction: row;
    margin-left: 50px;
  }
  & li {
    display: flex;
    list-style: none;
    color: $primary-10;
    font-weight: 400;
    font-size: 1.2rem;
    height: 64px;
    cursor: pointer;
    border-bottom: 1px solid $primary-50;
    @include md {
      border-bottom: none;
      min-width: 100px;
      padding: 0;
    }
    &:hover {
      background-color: $light_accent-lighter-15;
    }
    &.active {
      background-color: $light_accent-lighter-15;
    }
  }
  & li a {
    color: $primary-10;
    text-decoration: none;
    width: 100%;
    height: 100%;
    padding: 20px;
    @include md {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
