@import "src/styles/colors.scss";

body {
  margin: 0;
  font-family: Source Sans Pro, sans-serif;
  font-size: 18px;
  background-color: $primary-100 !important;
}

main {
  padding-top: 64px;
}
