@import "src/styles/colors.scss";

.incidentTypeLabel {
  display: inline-flex;
  align-items: center;
  border: $primary-50 1px solid;
  border-radius: 8px;
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  svg {
    width: 18px;
    height: 18px;
    margin-right: 0.25rem;
  }
}