@import "src/styles/colors.scss";

.header {
    display: flex;
    align-items: center;
    color: $primary-30;
    font-size: 16px;
    margin-bottom: 0.5rem;
}

.card {
    background-color: rgba($primary-100, 0.4);
    border-radius: 8px;
    padding: 0.5rem 1rem 0.5rem;
    p {
        color: $primary-10;
        font-weight: 400;
        font-size: 1rem;
        margin: 0;
    }
    margin-bottom: 1rem;
    position: relative;

    .editAndDeleteButtons {
        position: absolute;
        right: 0.25rem;
        top: 0.25rem;
        opacity: 0;
    }
    &:hover {
        .editAndDeleteButtons {
            opacity: 1;
        }
    }
    textArea {
        font-size: 16px;
    }

    &.addNote {
        cursor: text;
        .text {
            color: $primary-30;
            font-style: italic;
        }
        &:hover {
            .text {
                color: $primary-20;
            }
        }
    }
}

.editModeButtonsPanel {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}

.noted {
    font-size: 14px;
    color: $primary-40;
    margin-left: 0.5rem;
}
