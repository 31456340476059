@import "src/styles/colors.scss";
@import "src/styles/mixins";

.auditsTable {
  margin-top: 1rem;
  @include md {
    margin-top: 0;
  }

  table {
    td:nth-child(2) {
      border-radius: 4px 4px 0 0 !important;
    }
    td:nth-last-child(2) {
      border-radius: 0 0 4px 4px !important;
      padding-bottom: 1rem !important;
      @include md {
        border-radius: 0 !important;
        padding: 0.5rem !important;
      }
    }
    @include md {
      td:nth-child(2) {
        padding-left: 1rem !important;
        border-radius: 4px 0 0 4px !important;
      }
    }
  }
  tbody {
    tr {
      position: relative;
      margin-top: 1rem;
      @include md {
        position: initial;
      }
    }
  }
}

.disabledAudit {
  .disabledLabel {
    border: $primary-50 1px solid;
    color: $primary-40 !important;
    border-radius: 8px;
    margin-left: 0.75rem;
    padding: 0.25rem 0.5rem;
    font-size: 12px;
  }

  td {
    color: $primary-50 !important;
  }

  &:hover > td {
    color: $primary-20 !important;
  }
}

.auditName {
  display: flex;
  align-items: center;
}

@keyframes highlight {
  0% {
    background: $primary-90;
  }
  50% {
    background: $event;
    color: $primary-100;
  }
  100% {
    background: $primary-90;
  }
}

.highlight {
  td {
    animation: highlight 2s;
  }
}

.noData {
  min-height: 200px;
  background-color: $primary-90;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  margin-top: 0.5rem;
}

.mobileSortFilter {
  @include md {
    display: none;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  > div:nth-child(2) {
    margin-right: 1rem;
  }
}

.contextMenuCell {
  display: none !important;
  @include md {
    display: table-cell !important;
    text-align: right;
  }
}

.contextMenuCellMobile {
  position: absolute !important;
  top: 0.25rem;
  right: 0.25rem;
  z-index: 100;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  box-shadow: none !important;
  @include md {
    display: none;
  }
}
